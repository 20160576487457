.logo {
  padding-left: 100px;
}
.pages {
  padding-right: 100px;
}
@media(max-width:1100px){
  .logo {
    padding-left: 50px;
  }
  .pages {
    padding-right: 50px;
  }
}
@media (max-width:980px) {
  .logo {
    padding-left: 20px;
  }
  .pages {
    padding-right: 20px;
  }
}
@media (max-width:940px) {
  .logo {
    padding-left: 0;
  }
  .pages {
    padding-right: 0;
  }
}
