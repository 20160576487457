.rbc-event{
  background-color:#ECFFEC;
  color:#96E9C5;
}
.rbc-date-cell.rbc-now{
color: #0588fc ;
font-weight: bolder;
font-size: 20px;
}

.rbc-btn-group button{
  /* background-color:#e6e6e6; */
  margin-right:10px;
}

.myInput{
  border-radius: 10px;
  border:1px solid #ccc;
  margin-top:5px;
  text-align: center;
}
button:foucs{
  outline: none;
box-shadow: none;
}
.rbc-month-view{
z-index: 0;
}
.rbc-btn-group:nth-child(2){
display: none !important;
}
